@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Plus Jakarta Sans", sans-serif;
}
@layer components {
  .dropdown-button {
    @apply relative px-4 py-2 text-black rounded-md;
  }

  .dropdown-button::after {
    @apply absolute right-2 bottom-1 w-2 h-5 border-l-4 border-r-4  border-t-4 border-black;
    content: '';
  }

  .dropdown-button.open::after {
    @apply rotate-180;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
  padding: 12px 16px 12px 16px;
  background-color: #2e2e2e;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  font-weight: 500;
  color: white;
}

::-webkit-scrollbar {
  width: 15px;
  height: 16px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

customshadow {
  box-shadow: 4px 4px 16px rgba(222, 221, 221, 0.5);
}

@keyframes toast {
  from {
    transform: translateY(100%);
  }
}

.animate-toast {
  animation: toast 500ms cubic-bezier(0.16, 1, 0.3, 1) both;
  will-change: transform;
  bottom: 0;
  left: 2%;
}

.popup {
  position: absolute;
  background: white;
  inset: 0px auto auto 0px;
}

.Wrapper {
  position: relative;
  width: max-content;
}

.NativeSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-appearance: none;
}
